import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Avatar,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import agent from "api/agent";

const AddUserToCompanyModal = ({ open, onClose, onSuccess }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { data: companyData, isLoading: loadingCompanies } = useQuery(
    ["companies"],
    () => agent.Company.list(1, 100000)
  );

  const { data: userData, isLoading: loadingUsers } = useQuery(
    ["users"],
    () => agent.User.AllCustomers("?PageNumber=1&PageSize=1000000&UserType=2")
  );

  const companies = companyData?.items || [];
  const users = userData?.items || [];

  const addToCompanyMutation = useMutation(agent.Employee.AddToCompany, {
    onSuccess: () => {
      onSuccess();
      onClose();
      setSelectedUser(null);
      setSelectedCompany(null);
    },
    onError: (error) => {
      console.error('Failed to add user to company:', error);
    }
  });

  const handleAddUserToCompany = () => {
    if (selectedUser && selectedCompany) {
      addToCompanyMutation.mutate({
        userId: selectedUser.id,
        companyId: selectedCompany.id,
      });
    }
  };

  const renderUserOption = (props, user) => {
    const contactInfo = user.email || user.phoneNumber || 'No contact info';
    
    return (
      <li {...props}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Avatar 
            src={user.profilePhoto} 
            sx={{ 
              width: 32, 
              height: 32, 
              mr: 2,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            {user.nameSurname?.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="body1">
              {user.nameSurname}
            </Typography>
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ display: 'block' }}
            >
              {contactInfo}
            </Typography>
          </Box>
        </Box>
      </li>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          '& .MuiDialog-paper': {
            width: '100%'
          }
        }
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Əməkdaş Əlavə Et</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            value={selectedCompany}
            onChange={(_, newValue) => setSelectedCompany(newValue)}
            options={companies}
            getOptionLabel={(option) => option.name}
            loading={loadingCompanies}
            sx={{ mb: 3 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Şirkət"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          <Autocomplete
            value={selectedUser}
            onChange={(_, newValue) => setSelectedUser(newValue)}
            options={users}
            getOptionLabel={(option) => option.nameSurname}
            loading={loadingUsers}
            renderOption={renderUserOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label="İstifadəçi"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        <Button 
          onClick={() => {
            onClose();
            setSelectedUser(null);
            setSelectedCompany(null);
          }} 
          color="secondary"
          variant="outlined"
        >
          Bağla
        </Button>
        <Button
          onClick={handleAddUserToCompany}
          color="primary"
          variant="contained"
          disabled={!selectedUser || !selectedCompany || addToCompanyMutation.isLoading}
        >
          {addToCompanyMutation.isLoading ? 'Əlavə edilir...' : 'Əlavə Et'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserToCompanyModal;