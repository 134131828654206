import React, { useEffect, useState,useRef  } from "react";
import { useLocation } from "react-router-dom"
// Formik and Yup validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
// Ibeauty Admin Agent
import agent from "api/agent";

// Ant Design components
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Modal, Typography, Upload } from "antd";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import {useNavigate} from "react-router-dom";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const errorMessages = (error) => {
    return (
      <>
      <MDTypography variant="body2" color="error">
        {error}
      </MDTypography>
      </>
    )
  }

function ProductForm({ type }) {
  const setFieldValueRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const initialValues = {
    productId: "",
    name: "",
    price: "",
    productCost:"",
    specificPrice: "",
    specificPrice: 0,
    stock: 0,
    cosmetologOnly: false,
    commissionRate: "",
    brandId: "",
    ProductLanguageDescriptions: {},
    productPhotos: [],
    DeletedProductPhotos : [],
    ProductPrices: [{ quantity: 0, price: 0, commissionRate: 0 }]
  };

  const setSomeFieldValue = (name, value) => {
     if(setFieldValueRef.current){
      setFieldValueRef.current(name, value);
     }
  };

 const fetchCurrentProduct = async () => {
   if(type === "edit" && location.state?.id) {
    try{
      setLoading(true);
      const response = await agent.Product.details(location.state.id);
      const product = response;
      setSomeFieldValue("productId", product.id);
      setSomeFieldValue("name", product.name);
      setSomeFieldValue("price", product.price);
      setSomeFieldValue("productCost", product.productCost);
      setSomeFieldValue("specificPrice", product.specificPrice);
      setSomeFieldValue("stock", product.stock);
      setSomeFieldValue("cosmetologOnly", product.cosmetologOnly);
      setSomeFieldValue("commissionRate", product.commissionRate);
      setSomeFieldValue("brandId", product.brandId);
      product?.descriptions.forEach(desc => {
        setSomeFieldValue(`ProductLanguageDescriptions[${desc.languageCode}]`, desc.description);
      });
      product?.productPrices.forEach((price, index) => {
        setSomeFieldValue(`ProductPrices[${index}].quantity`, price.quantity);
        setSomeFieldValue(`ProductPrices[${index}].price`, price.price);
        setSomeFieldValue(`ProductPrices[${index}].commissionRate`, price.commissionRate);
      });
      const images = product?.images.map(photo => {
        return {
          uid: photo.substring(photo.lastIndexOf("/") + 1),
          name: photo.substring(photo.lastIndexOf("/") + 1),
          status: "done",
          url: photo
        }
      });
      setFileList(images);
      setCurrentImage(images);
    }catch(err){
        alert(JSON.stringify(err))
        setLoading(false);
    } 
    finally{
      setLoading(false);
    }
      
   }
  }

  const validationSchema = Yup.object().shape({
    brandId: Yup.string().required("Zəhmət olmasa şirkət seçin"),
    name: Yup.string()
      .required("Zəhmət olmasa adı daxil edin")
      .min(4, "Ad çox qısadır!")
      .max(50, "Ad çox uzundur!"),
    price: Yup.number()
      .min(1, "Qiymət 0-dan böyük olmalıdır")
      .required("Zəhmət olmasa qiymət daxil edin"),
    productCost: Yup.number()
      .min(1, "Qiymət 0-dan böyük olmalıdır")
      .required("Zəhmət olmasa qiymət daxil edin"),

    stock: Yup.number()
      .min(0, "Stok mənfi ola bilməz")
      .required("Zəhmət olmasa say daxil edin"),

    commissionRate: Yup.number()
      .test("commissionRate", "Komissiya - ola bilmez", (value) => {
        if (value < 0) {
          return false;
        }
        return true;
      })
      .max(50, "Komissiya 50-dən böyük ola bilməz"),
      productPhotos: Yup.array()
      .test('photo-required', 'At least one photo is required', function(photos) {
        if (type == 'edit') {
          return currentImage.length - initialValues.DeletedProductPhotos.length + photos.length == 0 ? false : true;
        }
        return photos.length > 0;
      }),
  });

  const [brands, setBrands] = React.useState([]);
  React.useEffect(() => {
    fetchCurrentProduct();
    agent.Brand.list(1, 1000).then((response) => {
      const brands = response?.items.map((brand) => {
        return {
          value: brand.id,
          label: brand.name
        };
      });
      setBrands(brands);
    })
  }, [setFieldValueRef.current]);

  const handleSubmit = (values, actions) => {
     values.DeletedProductPhotos = currentImage.filter(item => !fileList.
     includes(item)).map(item => item.uid)
    setLoading(true);
    agent.Product[type](values)
      .then(() => {
        actions.setSubmitting(false);
        setSuccessSB(true);
        actions.resetForm();
        setFileList([]);
        setCurrentImage([]);
        setLoading(false);
        navigate('/product/products');
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 {type === "create" ? "Yeni Məhsul" : "Məhsulun Redaktəsi"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => {
                    setFieldValueRef.current = setFieldValue;
                    return (
                    <Form>
                      <MDBox component="div" role="form">
                      <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            label="Productun Adı"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          {}
                          {touched.name && errors.name && (
                              errorMessages(errors.name)
                          )}
                        </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            name="brandId"
                            label="Brend"
                            component={SelectBox}
                            defaultValue={brands.find(brand => brand.value === values.brandId)}
                            options={brands ? brands : []}
                            onChange={(value) =>
                              setFieldValue("brandId", value)
                            }
                          />
                          {touched.brandId && errors.brandId && (
                              errorMessages(errors.brandId)
                          )}
                        </MDBox>
                        </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field
                            type="number"
                            name="productCost"
                            step="any"
                            id="productCost"
                            label="Maya qiyməti"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.productCost}
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          {touched.productCost && errors.productCost && (
                              errorMessages(errors.productCost)
                          )}
                          </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field
                            type="number"
                            name="specificPrice"
                            step="any"
                            id="specificPrice"
                            label="Müştəriyə satış qiyməti"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.specificPrice}
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          {touched.specificPrice && errors.specificPrice && (
                              errorMessages(errors.specificPrice)
                          )}
                          </MDBox>
                          </Grid>
                         <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field
                            type="number"
                            name="price"
                            step="any"
                            id="price"
                            label="Satış qiyməti"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.price}
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          {touched.price && errors.price && (
                              errorMessages(errors.price)
                          )}
                          </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                          <MDBox mb={2}>
                            <Field
                              type="number"
                              name="stock"
                              label="Stok sayı"
                              as={MDInput}
                              variant="standard"
                              fullWidth
                              value={values.stock}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field
                            type="number"
                            name="commissionRate"
                            step="any"
                            id="commissionRate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.commissionRate}
                            label="Kosmetologun qazancı"
                            as={MDInput}
                            variant="standard"
                            fullWidth
                          />
                          {touched.stock && errors.stock && (
                              errorMessages(errors.stock)
                          )}
                        </MDBox>
                        </Grid>
                        </Grid>
           {/* // Saya gore ferli qiymet  */}
<Grid container spacing={3}>
     {values?.ProductPrices?.map((price, index) => (
  <React.Fragment key={index}> 
  <Grid item xs={12} md={4}>
    <MDBox mb={2}>
    <Field 
     type="number"
     name={`ProductPrices[${index}].quantity`} 
     label="Miqdar"
     step="any"
      as={MDInput}
      variant="standard"
      fullWidth
      value={price.quantity}
      onBlur={
        (e) => {
          setFieldTouched(`ProductPrices[${index}].quantity`, true)
        }
      }
      onChange={
        (e) => {
          setFieldValue(`ProductPrices[${index}].quantity`, e.target.value)
      }
    }
     />
    </MDBox>
   </Grid>
   <Grid item xs={12} md={3}>
    <MDBox mb={2}>
    <Field
      type="number"
      name={`ProductPrices[${index}].price`}
      label="Qiymət"
      step="any"
      as={MDInput}
      variant="standard"
      fullWidth
      value={price.price}
      onBlur={
        (e) => {
          setFieldTouched(`ProductPrices[${index}].price`, true)
        }
      }
      onChange={
        (e) => {
          setFieldValue(`ProductPrices[${index}].price`, e.target.value)
      }
    }
      />
    </MDBox>
   </Grid>
   <Grid item xs={12} md={4}>
    <MDBox mb={2}>
    <Field
    type="number"
    name={`ProductPrices[${index}].commissionRate`}
    label="Kosmetologun qazancı"
    step="any"
    as={MDInput}
    variant="standard"
    fullWidth
    value={price.commissionRate}
    onBlur={
      (e) => {
        setFieldTouched(`ProductPrices[${index}].commissionRate`, true)
      }
    }
    onChange={
      (e) => {
        setFieldValue(`ProductPrices[${index}].commissionRate`, e.target.value)
    }
  }
  />
     </MDBox>
    </Grid>
    <Grid item xs={12} md={1}>
    <MDBox mb={2}>
    <IconButton
    style=  {{ color: "red" }}
    onClick={() => {
      setFieldValue("ProductPrices", [
        ...values.ProductPrices.filter((_, i) => i !== index),
      ]);
    }}
    >
    <DeleteIcon />
    </IconButton>
    </MDBox>
    </Grid>
    </React.Fragment>
))}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MDBox mt={4} mb={1}>
                      <MDButton
                         onClick={() => {
                           setFieldValue("ProductPrices", [
                             ...values.ProductPrices,
                             { quantity: 0, price: 0, commissionRate: 0 },
                           ]);
                         }}
                         disabled={loading}
                         type="button"
                         variant="gradient"
                         color="info"
                         fullWidth
                         >
                          Saya görə qiymət
                        </MDButton>
                    </MDBox>
                      </Grid>
           {/* // Saya gore ferqli qiymet son */}
                        <MDBox mb={2}>
                          <FormControlLabel
                            name="isSpecial"
                            control={<Switch/>}
                            checked={values.cosmetologOnly}
                            label="Kosmetoloqa özəl"
                            onChange={(e) =>
                              setFieldValue("cosmetologOnly", e.target.checked)
                            }
                          />
                        </MDBox>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            name="ProductLanguageDescriptions[az-AZ]"
                            label="Azərbaycan dili üçün məlumat"
                            component={MDInput}
                            value={values.ProductLanguageDescriptions["az-AZ"]}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(e) =>
                              setFieldValue(
                                "ProductLanguageDescriptions[az-AZ]",
                                e.target.value
                              )
                            }
                          />
                          {touched.productLanguageDescriptions &&
                            errors.productLanguageDescriptions && (
                              errorMessages(errors.productLanguageDescriptions)
                            )}
                        </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            name="ProductLanguageDescriptions[en-US]"
                            label="İngilis dili üçün məlumat"
                            component={MDInput}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            value={values.ProductLanguageDescriptions["en-US"]}
                            onChange={(e) =>
                              setFieldValue(
                                "ProductLanguageDescriptions[en-US]",
                                e.target.value
                              )
                            }
                          />
                        </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            name="ProductLanguageDescriptions[tr-TR]"
                            label="Türk dili üçün məlumat"
                            component={MDInput}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            value={values.ProductLanguageDescriptions["tr-TR"]}
                            onChange={(e) =>
                              setFieldValue(
                                "ProductLanguageDescriptions[tr-TR]",
                                e.target.value
                              )
                            }
                          />
                        </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <MDBox mb={2}>
                          <Field
                            name="ProductLanguageDescriptions[ru-RU]"
                            label="Rus dili üçün məlumat"
                            component={MDInput}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            value={values.ProductLanguageDescriptions["ru-RU"]}
                            onChange={(e) =>
                              setFieldValue(
                                "ProductLanguageDescriptions[ru-RU]",
                                e.target.value
                              )
                            }
                          />
                        </MDBox>
                        </Grid>
                        </Grid>
                        <MDBox mb={2}>
                          <Divider />
                          <MDTypography
                            variant="h6"
                            component="h6"
                            gutterBottom
                          >
                            Şəkil əlavə et
                          </MDTypography>
                           <Field>
                            {({ field }) => (
                              <>
                                <Upload
                                  customRequest={({ file, onSuccess }) => {
                                    onSuccess("ok");
                                  }}
                                  listType="picture-card"
                                  fileList={fileList}
                                  accept=".png,.jpg,.jpeg,.gif"
                                  onPreview={handlePreview}
                                  onChange={(file) => {
                                    setFileList(file.fileList);
                                    setFieldValue(
                                      "productPhotos",
                                      file.fileList.map(
                                        (item) => item.originFileObj
                                      )
                                    );
                                  }}
                                >
                                  {fileList.length >= 3 ? null : uploadButton}
                                </Upload>
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  footer={null}
                                  onCancel={handleCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImage}
                                  />
                                </Modal>
                                {touched.productPhotos &&
                                  errors.productPhotos && (
                                    errorMessages(errors.productPhotos)
                                  )}
                              </>
                            )}
                          </Field>
                        </MDBox>
                        <MDBox mb={2}></MDBox>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          ml={-1}
                        ></MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={loading}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {type === "create" ? "Əlavə Et" : "Yenilə"}
                          </MDButton>
                        </MDBox>
                        <MDBox
                          mt={1}
                          mb={1}
                          style={{ display: loading ? "block" : "none" }}
                        >
                          <LinearProgress
                            color="info"
                            hidden={true}
                            style={{
                              width: "50%",
                              margin: "auto",
                              position: "relative"
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Form>
                    )
                  }}
                </Formik>
                <MDSnackbar
                  color="success"
                  icon="check"
                  title="Uğurlu Əməliyyat"
                  content={
                    type === "create"
                      ? "Product Əlavə Edildi"
                      : "Product Yeniləndi"
                  }
                  dateTime={new Date().toLocaleString().split(",")[0]}
                  open={successSB}
                  onClose={closeSuccessSB}
                  close={closeSuccessSB}
                  bgWhite
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default ProductForm;