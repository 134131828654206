import  axios from "axios";
import {store} from "store/configureStore";
import { setToken,signOutUser} from "redux/accountSlice";
import {setNotification,removeNotification,loadingAction} from "redux/globalNotificationSlice";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));

const axiosApiInstance = axios.create({
  baseURL: "https://api.ibu.az/api/",
});

const responseBody = (response) => response?.data;

axiosApiInstance.interceptors.request.use((config) => {
    store.dispatch(removeNotification());
    store.dispatch(loadingAction(true));
    const token = store.getState().account.token?.accessToken;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Accept-Language"] = `en-US,en;q=0.9,tr;q=0.8,az;q=0.7`;
    return config;
  },
  error => Promise.reject(error)
);

 axiosApiInstance.interceptors.response.use(
  async (response) => {
    store.dispatch(loadingAction(false));
    await sleep();
    return response;
  },
  async (error) => {
    store.dispatch(loadingAction(false));
     const { data, status } = error?.response;
     const originalRequest = error?.config;
        switch (status) {
            case 400:
                if (data.errors) {
                    const modelStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modelStateErrors.push({
                                key,
                                value: data.errors[key],
                            })
                        }
                    }
                    throw modelStateErrors.flat();
                }
                else if (data) {
                    throw data;
                }
                break;
         case 401:
         if (!originalRequest._retry && typeof data !== "object") {
          originalRequest._retry = true;
           try {
              const refreshToken  = store.getState().account.token?.refreshToken;
              const rs = await axios.post("https://api.ibu.az/api/Auth/refresh-token-login", {
                refreshToken: refreshToken
              });
                store.dispatch(setToken(rs.data));
                axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${rs.data.accessToken}`;
                return axiosApiInstance(originalRequest);
           }
           catch {
                store.dispatch(setNotification({
                  message:"Sizin sessiyanızın vaxtı bitmişdir,zəhmət olmasa yenidən daxil olun",
                  title:"Sessiyanızın vaxtı bitmişdir",
                  type:"error"
                }));
                store.dispatch(signOutUser())
            }
           }
           else{
              store.dispatch(setNotification({
                title: "Unauthorized",
                message:data?.detail,
                type:"error"
              }));
           }
             break;
             case 404:
              throw data;
             break;
             case 409:
              throw data;
              break;
              case 422:
                throw data;
              break;
          case 500:
             alert("Server error - check the terminal for more info!");
             break;
         default:
             break;
     }
     return Promise.reject(error.response);
  }
);

const requests = {
  get: (url) =>
  axiosApiInstance
      .get(url, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then(responseBody),
  post: (url, body) =>  axiosApiInstance.post(url, body).then(responseBody),
  put: (url, body) =>  axiosApiInstance.put(url, body).then(responseBody),
  delete: (url) =>  axiosApiInstance.delete(url).then(responseBody),
  deleteBody: (url, payload) =>  axiosApiInstance.delete(url, { data: payload }).then(responseBody),
  postForm: (url, data) =>
  axiosApiInstance
      .post(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  putForm: (url, data) =>
  axiosApiInstance
      .put(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
};

function createFormData(item) {
    let formData = new FormData();
    for (const key in item) {
        if (key === 'ProductLanguageDescriptions') {
            const descriptions = item[key];
            for (const lang in descriptions) {
              formData.append(`ProductLanguageDescriptions[${lang}]`, descriptions[lang]);
            }
        } 
        else if (key === 'categoryLanguages'){
          item[key].forEach((lang, index) => {
            formData.append(`categoryLanguages[${index}].LanguageCode`, lang.languageCode);
            formData.append(`categoryLanguages[${index}].Name`, lang.name);
          });
        }
        else if  (key === "ProductPrices") {
              item[key].forEach((price, index) => {
              formData.append(`ProductPrices[${index}].Quantity`, price.quantity);
              formData.append(`ProductPrices[${index}].Price`, price.price);
              formData.append(`ProductPrices[${index}].CommissionRate`, price.commissionRate);
            });
        }
        else if (Array.isArray(item[key])) {
            item[key].forEach((file) => {
                formData.append(key, file);
            });
        }
        
        else {
            formData.append(key, item[key]);
        }
    }
    return formData;
}

const ApprovalRequests = {
    list: (pageNumber, pageSize) => requests.get(`Users/get-user-approval-reuests?pageNumber=`+pageNumber+"&pageSize="+pageSize),
    ApproveOrReject:(value) => requests.post(`Auth/user-approve-or-reject`,value),
}

const Account = {
    profile: () => requests.get("Account"),
    login: (value) => requests.post("Auth/Login", value),
}


const Couriers={
    list: () => requests.get(`TelegramBot/couriers`),
    delete: (id) => requests.delete(`TelegramBot/delete-courier/${id}`),
    acceptOrReject: (value) => requests.put(`TelegramBot/accept-or-reject-courier`,value),
}

const User = {
    Allcosmetologists: (query) => requests.get(`Users/get-users${query}`),
    AllCustomers: (query) => requests.get(`Users/get-users${query}`),
    GetUsersWithQueryModel:(query) => requests.get(`Users/get-users-with-query-model${query}`),
    customers: (pageNumber,pageSize) => requests.get(`Users/get-users?PageNumber=`+pageNumber+"&pageSize="+pageSize+"&UserType=customer"),
    cosmetologist : (pageNumber,pageSize) => requests.get(`Users/get-users?PageNumber=`+pageNumber+"&pageSize="+pageSize+"&UserType=cosmetologist"),
    fullDetails: (id) => requests.get(`users/full-detail/${id}`),
    addCommissionRate: (value) => requests.post(`Users/add-commision`,value),
    increaseOrDecreaseBonus: (value) => requests.post(`Users/increase-or-decrease-bonus`,value),
}

const Brand = {
  getAllBrands: (query) => requests.get(`brand/GetAllBrands${query}`),
  list: (pageNumber, pageSize) => requests.get(`Brand/GetAllBrands?start=${pageNumber}&size=${pageSize}`),
  details: (id) => requests.get(`brands/get-brand-details?brandId=` + id),
  create: (brand) => requests.postForm(`brand/AddBrand`, createFormData(brand)),
  edit: (brand) => requests.putForm(`brand/UpdateBrand`, createFormData(brand)),
  delete: (ids) => requests.deleteBody(`brand/delete`, ids),
  updateDisplayOrder: (value) => requests.put(`brand/update-brand-display-order`, value),
};

const Company = {
    getAllCompanies: (query) => requests.get(`Company/admin/companies${query}`),
    list: (pageNumber, pageSize) => requests.get(`Company/GetCompanies?pageNumber=`+pageNumber+"&pageSize="+pageSize),
    details: (id) => requests.get(`Companies/get-company-details?companyId=`+id),
    create: (company) => requests.postForm(`Company/AddCompany`,createFormData(company)),
    edit: (company) => requests.putForm(`Company/UpdateCompany`,createFormData(company)),
    delete: (ids) => requests.deleteBody(`Company/delete`,ids),
    updateDisplayOrder: (value) => requests.put(`Company/update-companies-display-order`, value),
}

const Product = {
    getAllProducts: (query) => requests.get(`Product/GetAll${query}`),
    list: (pageNumber, pageSize) => requests.get(`Product/GetAll?start=`+pageNumber+"&size="+pageSize),
    listByBrand: (pageNumber, pageSize,brandId) => requests.get(`Product/GetByBrand?BrandId=`+brandId+"&PageNumber="+pageNumber+"&PageSize="+pageSize),
    details: (id) => requests.get(`Product/GetAdminProductDetails?ProductId=`+id),
    create: (product) => requests.postForm(`Product/AddProduct`,createFormData(product)),
    edit: (product) => requests.putForm(`Product/UpdateProduct`,createFormData(product)),
    delete: (ids) => requests.deleteBody(`Product/delete`,ids),
}

const PermittedArea = {
  AddOrUpdatePermittedArea: (area) =>
    requests.post(`MapPermittedArea/AddOrUpdatePermittedArea`, area),
  GetPermittedArea: () => requests.get(`MapPermittedArea/PermittedAreas`),
};

const Discount = {
  discountsWithProducts: (query) => requests.get(`ProductDiscount/DiscountsWithProducts${query}`),
  create: (discount) => requests.post(`ProductDiscount/AddDiscount`, discount),
  alldiscounts: () => requests.get(`ProductDiscount/AllDiscounts`),
  addProductToDiscount: (discount) =>
    requests.post(`ProductDiscount/AddProductsToDiscount`, discount),
  removeDiscountFromProduct: (id) => requests.delete(`productdiscount/` + id),
  delete: (ids) => requests.deleteBody(`productdiscount/delete`, ids),
};

const Coupon = {
  getAllCoupons: (query) => requests.get(`Cupon/Coupons${query}`),
  addCoupon: (coupon) => requests.post(`Cupon/AddCupon`, coupon),
  list: () => requests.get(`Cupon/AllCoupons`),
  assignCouponToUser: (coupon) => requests.post(`Cupon/AssignCouponToUser`, coupon),
  delete: (ids) => requests.deleteBody(`Cupon/delete`,ids),
};

const Slider = {
    list: (sliderPage) => requests.get(`slider/GetSliders?sliderPage=${sliderPage}`),
    create: (slider) => requests.postForm(`slider/add`,createFormData(slider)),
    delete: (id) => requests.delete(`slider/delete/`+id)
}

const Orders = {
  list: (query) => requests.get(`order/all-orders${query}`),
  details: (id) => requests.get(`Order/GetOrderDetails?orderId=`+id),
  updateOrderStatus: (order) => requests.put(`Order/UpdateOrderStatus`, order),
  delete: (id) => requests.delete(`Order/`+id),
};

const TopHighlight = {
  list: (query) => requests.get(`TopHighlight/top-highlights${query}`),
  add: (highlight) => requests.post(`TopHighlight/AddTopHighlight`, highlight),
  activeDeactive: (value) => requests.put(`TopHighlight/ActivateDeactivate`, value),
  delete: (ids) => requests.deleteBody(`TopHighlight/delete`,ids),
};

const AppVersionManagment = {
  list: (query) => requests.get(`appVersionmanagment/versions${query}`),
  add: (version) => requests.post(`AppVersionManagment/AddAppVersion`, version),
  activeDeactive: (value) => requests.put(`AppVersionManagment/ActivateDeactivate`, value),
};

const PushNotification = {
    Send: (notification) => requests.post("PushNotification",notification)
}

const Employee ={
  All: (pageNumber, pageSize) => requests.get(`employees/all-company-employee?pageNumber=`+pageNumber+"&pageSize="+pageSize),
  AddToCompany: (value) =>  requests.post("employees/create-company-employee", value)
}

const Catalog = {
   Brands:() => requests.get('catalog/brands/select'),
   ProductsByBrand:(brandId) => requests.get(`catalog/products/select-by-brand/${brandId}`)
}

const Category = {
   Add: (category) => requests.postForm(`category/add`,createFormData(category)),
   Update: (category) => requests.putForm(`category/update`,createFormData(category)),
   Get: () => requests.get("category/admin/getlist"),
   AddProducts: (values) => requests.post("Category/add-products",values),
   RemoveProduct:(value) => requests.deleteBody("category/remove-products",value),
   Remove:(id) =>requests.delete(`Category/delete/${id}`)
}


const agent ={
    Category,
    Catalog,
    Employee,
    PushNotification,
    Account,
    ApprovalRequests,
    User,
    Brand,
    Company,
    Product,
    Slider,
    Orders,
    Coupon,
    Discount,
    PermittedArea,
    TopHighlight,
    AppVersionManagment,
    Couriers
}

export default agent ;
